import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// import { LogtoProvider, LogtoConfig } from '@logto/react' //LogtoConfig пока что не стирать, этот тип возможно будет нужен в будущем
import { LogtoProvider } from '@logto/react'
import HomePage from './pages/HomePage'
import Layout from './components/Layout'
import NotFoundPage from './pages/NotFoundPage'
import CallbackPage from './pages/CallbackPage'
import CONSTANTS from './constants.js'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { fetchJsonData, fetchSharedData } from '@learnhub/language-ui'
import customThemes from './store/DataJSON/themes.json'
import sharedThemes from '@learnhub/shared-ui/themes'

const config = {
  endpoint: CONSTANTS.REACT_APP_LOGTO_ENDPOINT,
  appId: CONSTANTS.REACT_APP_LOGTO_APPID,
  scopes: ['email', 'openid', 'profile', 'offline_access']
}

function App () {
  const dispatch = useDispatch()
  const theme = useSelector(state => state.settingsStore.data.theme)
  const jsonData = useSelector(state => state.jsonDataStore.jsonData)
  const sharedData = useSelector(state => state.jsonDataStore.sharedData)
  const error = useSelector(state => state.jsonDataStore.error)
  const errorShared = useSelector(state => state.jsonDataStore.errorShared)

  useEffect(() => {
    const updateThemeVariables = () => {
      const themes = {
        dark: { ...sharedThemes.dark, ...customThemes.dark },
        light: { ...sharedThemes.light, ...customThemes.light }
      }
      const themeVariables = themes[theme]
      for (const [key, value] of Object.entries(themeVariables)) {
        document.documentElement.style.setProperty(key, value)
      }
    }

    updateThemeVariables()
  }, [theme])

  useEffect(() => {
    AOS.init({ duration: 1000 })
  }, [])

  useEffect(() => {
    if (errorShared === null && Object.keys(sharedData).length === 0) {
      dispatch(fetchSharedData())
    }
  }, [dispatch, errorShared, sharedData])

  useEffect(() => {
    if (error === null && Object.keys(jsonData).length === 0) {
      dispatch(fetchJsonData())
    }
  }, [dispatch, error, jsonData])

  return (
    <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <LogtoProvider config={config}>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path='*' element={<NotFoundPage />} />
            <Route path='/callback' element={<CallbackPage />} />
          </Route>
        </Routes>
      </LogtoProvider>
    </Router>
  )
}

export default App
