import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { Provider } from 'react-redux'
import store from './store'
import App from './App'
import '@learnhub/shared-ui/style'
import '@learnhub/language-ui/style'
import { LanguageProvider } from '@learnhub/language-ui'
import { setConfig } from '@learnhub/language-ui'
import languageConfig from './languageConfig'

setConfig(languageConfig)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </Provider>
)
