import React from 'react'
import { Link } from 'react-router-dom'
import styles from './NotFound.module.sass'
import { useSelector } from 'react-redux'

function NotFound () {
  const language = useSelector(state => state.settingsStore.data.language)
  const page = useSelector(state => state.recordStore.page)
  const records = useSelector(state => state.recordStore.records)

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.notFoundCode}>
          <p className={styles.code}>404</p>
        </div>
        <div className={styles.line}></div>
        <div className={styles.notFoundContent}>
          <h2 className={styles.title}>
            {records[page]?.['notfound-title']?.[language]}
          </h2>
          <div className={styles.text}>
            <p>{records[page]?.['notfound-text']?.[language]}</p>
            <a href='/' className={styles.linkText}>
              {records[page]?.['notfound-support-link']?.[language]}
            </a>
          </div>
          <div className={styles.flexBox}>
            <Link to='/' className={styles.seeMoreBtn}>
              <span className={styles.btnText}>
                {records[page]?.['notfound-btn']?.[language]}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
