import { combineReducers } from 'redux'
import {
  settingsReducer,
  languageReducer,
  recordReducer,
  jsonDataReducer
} from '@learnhub/language-ui'

const rootReducer = combineReducers({
  settingsStore: settingsReducer,
  languageStore: languageReducer,
  recordStore: recordReducer,
  jsonDataStore: jsonDataReducer
})

export default rootReducer
