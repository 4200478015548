import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Banner from '../../components/Banner'
import ScrollBanner from '../../components/ScrollBanner'
import WhatYouCanFind from '../../components/WhatYouCanFind'
import ReviewList from '../../components/ReviewsList'
import QandA from '../../components/Q&A'
import WhatWeAchieved from '../../components/WhatWeAchieved'
import ComeToUs from '../../components/ComeToUs'
import { changePage } from '@learnhub/language-ui'

function HomePage () {
  const dispatch = useDispatch()
  const page = useSelector(state => state.recordStore.page)

  useEffect(() => {
    if (page !== 'mainpage') {
      dispatch(changePage({ page: 'mainpage' }))
    }
  }, [dispatch, page])

  return (
    <>
      <Banner />
      <ScrollBanner />
      <WhatYouCanFind />
      <ReviewList />
      <WhatWeAchieved />
      <ComeToUs />
      <QandA />
    </>
  )
}

export default HomePage
