import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import styles from './Banner.module.sass'
import words from '../../store/DataJSON/bannerWords.json'

function Banner ({ settings, records, page }) {
  const [text, setText] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [loopNum, setLoopNum] = useState(0)
  const [charIndex, setCharIndex] = useState(0)
  const typingSpeed = isDeleting ? 100 : 150
  const pauseBetweenWords = 1000
  const [aosEffect, setAosEffect] = useState('fade-right')
  const { theme, language } = settings.data
  const { links, bannerDesc } = useSelector(
    state => state.jsonDataStore.jsonData
  )

  useEffect(() => {
    const handleResize = () => {
      const effect = window.innerWidth <= 500 ? 'fade-up' : 'fade-right'
      setAosEffect(effect)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const handleTyping = () => {
      const currentWord = words[loopNum % words.length]
      const isFullWordTyped = charIndex === currentWord.length
      const isWordFullyDeleted = charIndex === 0

      if (isFullWordTyped && !isDeleting) {
        setTimeout(() => setIsDeleting(true), pauseBetweenWords)
      } else if (isWordFullyDeleted && isDeleting) {
        setIsDeleting(false)
        setLoopNum(loopNum + 1)
      } else {
        const nextCharIndex = isDeleting ? charIndex - 1 : charIndex + 1
        setCharIndex(nextCharIndex)
        setText(currentWord.substring(0, nextCharIndex))
      }
    }

    const typingTimeout = setTimeout(handleTyping, typingSpeed)

    return () => clearTimeout(typingTimeout)
  }, [charIndex, isDeleting, loopNum, typingSpeed])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div
          key={aosEffect}
          className={styles.textOverlay}
          data-aos={aosEffect}
          data-aos-delay='200'
        >
          <h2 className={styles.textTitle}>
            <span className={styles.blueSpan}>
              {records[page]?.['banner-blue-title']?.[language]}
            </span>
            {records[page]?.['banner-title']?.[language]}
          </h2>
          <p className={styles.textUnderTitle}>
            <span className={styles.textPart}>
              {records[page]?.['banner-title-secondary']?.[language]}
            </span>
            <span className={styles.textPartWithBlueSpan}>
              <span className={styles.blueSpan}>{text}</span>|
            </span>
          </p>
          <div className={styles.bannerBtns}>
            <a
              href={links?.buttons?.['banner-btn1']?.url ?? '/'}
              className={styles.bannerBtnFirst}
            >
              <p className={styles.bannerBtnText}>
                {records[page]?.['banner-btn1']?.[language]}
              </p>
            </a>
            <a
              href={links?.buttons?.['banner-btn2']?.url ?? '/'}
              className={styles.bannerBtnSecond}
            >
              <p className={styles.bannerBtnText}>
                {records[page]?.['banner-btn2']?.[language]}
              </p>
            </a>
          </div>
          <div className={styles.bannerDesc}>
            {bannerDesc?.map((item, index) => (
              <div key={index} className={styles.descItem}>
                <div className={styles.imgBox}>
                  <img
                    className={styles.descImg}
                    src={item.imgSrc}
                    alt={item.alt}
                  />
                  <p className={styles.descText}>{item.count}</p>
                </div>
                <p className={styles.descText}>
                  {records[page]?.[item.descriptionKey]?.[language]}
                </p>
              </div>
            ))}
          </div>
        </div>
        <img
          data-aos='fade-left'
          data-aos-delay='500'
          className={styles.img}
          src={`/images/EduOnline-${theme === 'dark' ? 'white' : 'blue'}.png`}
          alt='EduOnline-white'
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  settings: state.settingsStore,
  records: state.recordStore.records,
  page: state.recordStore.page
})

export default connect(mapStateToProps, null)(Banner)
