import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './ErrorNotification.module.sass'
import ErrorBox from '../ErrorBox'
import {
  clearRecordError,
  clearLanguageError,
  clearJsonDataError
} from '@learnhub/language-ui'

const ErrorNotification = () => {
  const dispatch = useDispatch()
  const recordError = useSelector(state => state.recordStore.error)
  const recordHeaderError = useSelector(state => state.recordStore.errorHeader)
  const recordFooterError = useSelector(state => state.recordStore.errorFooter)
  const languageError = useSelector(state => state.languageStore.error)
  const jsonDataError = useSelector(state => state.jsonDataStore.error)
  const sharedDataError = useSelector(state => state.jsonDataStore.errorShared)

  const [errors, setErrors] = useState({})

  const hideError = useCallback(key => {
    setErrors(prev => ({
      ...prev,
      [key]: { ...prev[key], visible: false }
    }))
  }, [])

  const removeError = useCallback(
    (type, key) => {
      setErrors(prev => {
        const { [key]: _, ...rest } = prev
        return rest
      })

      switch (type) {
        case 'record':
          dispatch(clearRecordError('error'))
          break
        case 'recordHeader':
          dispatch(clearRecordError('errorHeader'))
          break
        case 'recordFooter':
          dispatch(clearRecordError('errorFooter'))
          break
        case 'language':
          dispatch(clearLanguageError())
          break
        case 'jsonData':
          dispatch(clearJsonDataError('error'))
          break
        case 'sharedData':
          dispatch(clearJsonDataError('errorShared'))
          break
        default:
          break
      }
    },
    [dispatch]
  )

  const addError = useCallback(
    (type, error) => {
      const key = `${type}-${new Date().getTime()}`
      const newError = { type, data: error, visible: true }

      setErrors(prev => ({
        ...prev,
        [key]: newError
      }))

      const hideTimeout = setTimeout(() => {
        hideError(key)
      }, 10000)

      const removeTimeout = setTimeout(() => {
        removeError(type, key)
      }, 15000)

      return () => {
        clearTimeout(hideTimeout)
        clearTimeout(removeTimeout)
      }
    },
    [removeError, hideError]
  )

  useEffect(() => {
    if (recordError) {
      addError('record', recordError)
    }
  }, [recordError, addError])

  useEffect(() => {
    if (recordHeaderError) {
      addError('recordHeader', recordHeaderError)
    }
  }, [recordHeaderError, addError])

  useEffect(() => {
    if (recordFooterError) {
      addError('recordFooter', recordFooterError)
    }
  }, [recordFooterError, addError])

  useEffect(() => {
    if (languageError) {
      addError('language', languageError)
    }
  }, [languageError, addError])

  useEffect(() => {
    if (jsonDataError) {
      addError('jsonData', jsonDataError)
    }
  }, [jsonDataError, addError])

  useEffect(() => {
    if (sharedDataError) {
      addError('sharedData', sharedDataError)
    }
  }, [sharedDataError, addError])

  const visibleErrors = useMemo(
    () => Object.values(errors).filter(error => error.visible),
    [errors]
  )

  if (visibleErrors.length === 0) return null

  return (
    <div className={styles.notificationBox}>
      <div className={styles.errorNotificationContainer}>
        {visibleErrors.slice(0, 5).map((error, index) => {
          const key = Object.keys(errors).find(k => errors[k] === error)

          return (
            <ErrorBox key={key} error={error} onHide={() => hideError(key)} />
          )
        })}
      </div>
    </div>
  )
}

export default ErrorNotification
