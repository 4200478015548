import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useHandleSignInCallback } from '@logto/react'
import Loader from '../../components/Loader'

const CallbackPage = () => {
  const navigate = useNavigate()
  const { isLoading, error } = useHandleSignInCallback(() => navigate('/'))

  useEffect(() => {
    if (error) {
      //Пишем что ошибка на фулл страницу, для этого за основу юзаем not found page
      console.error('Sign-in callback error:', error)
    }
  }, [error])

  if (isLoading) {
    return <Loader />
  }

  return <Loader />
}

export default CallbackPage
