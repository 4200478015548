import React from 'react'
import styles from './ComeToUs.module.sass'
import { connect, useSelector } from 'react-redux'

function ComeToUs ({ settings, records, page }) {
  const links = useSelector(state => state.jsonDataStore.jsonData.links)
  const { language } = settings.data
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.text}>
          <span className={styles.blueText}>
            {records[page]?.['come-to-us-blu-text1']?.[language]}
          </span>{' '}
          {records[page]?.['come-to-us-text1']?.[language]}{' '}
          <span className={styles.blueText}>
            {records[page]?.['come-to-us-blu-text2']?.[language]}
          </span>{' '}
          {records[page]?.['come-to-us-text2']?.[language]}{' '}
          <span className={styles.blueText}>
            {records[page]?.['come-to-us-blu-text3']?.[language]}
          </span>{' '}
          {records[page]?.['come-to-us-text3']?.[language]}
        </p>
        <a
          href={links?.buttons?.['come-to-us-btn']?.url ?? '/'}
          className={styles.btn}
        >
          <span className={styles.btnText}>
            {records[page]?.['come-to-us-btn']?.[language]}
          </span>
        </a>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  settings: state.settingsStore,
  records: state.recordStore.records,
  page: state.recordStore.page
})

export default connect(mapStateToProps, null)(ComeToUs)
